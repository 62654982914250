import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesAIKraken: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Anomaly Interception - Kraken</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ai_kraken.webp"
            alt="Interception Kraken"
          />
        </div>
        <div className="page-details">
          <h1>Anomaly Interception - Kraken</h1>
          <h2>
            Struggling with clearing Anomaly Interception - Kraken? Check out
            our Guide below!
          </h2>
          <p>
            Last updated: <strong>30/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          If you haven’t checked our previous guide about Anomaly Interception,
          make sure to drop a view
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Anomaly Interception"
            link="/nikke/guides/game-modes-anomaly-interception"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_ai.webp"
                alt="Interception"
              />
            }
          />
        </Row>
        <p>
          For today, we will start with the most famous boss: Kraken (because it
          drops Custom Modules)
        </p>
        <SectionHeader title="Rewards" />
        <p>
          Kraken is an Iron boss that is weak against Wind-code Nikkes, and when
          you pass a certain stage, the reward (mostly Custom Modules) will
          increase. The rewards are:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Reward</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <ul>
                  <li>Custom Lock x2</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x16</li>
                  <li>31% chance to have Custom Module (1~2)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <ul>
                  <li>Custom Lock x2</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x17</li>
                  <li>32% chance to have Custom Module (1~2)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <ul>
                  <li>Custom Lock x4</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x16</li>
                  <li>42% chance to have Custom Module (1~2)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <ul>
                  <li>Custom Lock x4</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x25</li>
                  <li>43% chance to have Custom Module (1~2)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <ul>
                  <li>Custom Lock x4</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x26</li>
                  <li>44% chance to have Custom Module (1~2)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <ul>
                  <li>Custom Lock x6</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x27</li>
                  <li>56% chance to have Custom Module (1~3)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                <ul>
                  <li>Custom Lock x6</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x37</li>
                  <li>58% chance to have Custom Module (1~3)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                <ul>
                  <li>Custom Lock x6</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x37</li>
                  <li>58% chance to have Custom Module (1~3)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                <ul>
                  <li>Custom Lock x6</li>
                  <li>Random T9 gear x7</li>
                  <li>Custom Module Shard x37</li>
                  <li>58% chance to have Custom Module (1~3)</li>
                  <li>The Title: Prince of the Sea</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>As you noticed, there are a few good checkpoints here:</p>
        <ul>
          <li>Stage 3: Better % drop Custom Module than 2 previous stages.</li>
          <li>
            Stage 6: Not only the % drop increases, but also the amount of CM
            can drop is increased up to 3.
          </li>
          <li>Stage 7: Max reward</li>
        </ul>
        <SectionHeader title="Teams" />
        <p>
          We know that Kraken is a Wind-weak Rapture, that’s why we should use
          Wind-DPS here with as high Elemental Damage as possible (unless your
          Wind-dps are not invested enough). However, the fact is that we are
          forced to bring a wind unit against Kraken because of their being a
          QTE (red circles that you need to destroy before time runs out) which
          only Wind Code Nikkes can hit. The following are the ideal Wind DPS
          units to use:
        </p>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="scarlet-black-shadow"
            enablePopover
          />
          : The goat of all Wind DPS. Your Kraken run will be so much more
          comfortable if you have a good SBS in hand.
        </p>
        <p>
          <NikkeCharacter
            mode="inline"
            slug="sakura-bloom-in-summer"
            enablePopover
          />
          : If you by chance have a well built Summer Sakura, then don’t
          hesitate and put her in the team. She's an auto friendly Nikke
          (Assault Rifle) and Kraken spawns a lot of parts for Sakura to
          repeatedly trigger her D.o.T.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="alice" enablePopover />: She deals
          good damage (should be one third of peak SBS), but her role in the
          team is mostly buffing SBS’s damage by 30%.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="red-hood" enablePopover />: She is
          always a good choice for when you lack any meta DPS. If you do not
          have any other meta DPS, use Red Hood. In the teams mentioned below,
          if you do not have the mentioned built properly or do not have the
          unit at all, just use Red Hood!
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="noir" enablePopover />: People
          remember Noir for 2 things, and we will add the third one! A strong
          Shotgun DPS for Wind weak, and she can perform well solo, not like her
          little sister.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="guilty" enablePopover />: She’s
          also a decent DPS, and people have run her against Wind-weak Rapture
          before. Not as great as the other 3 but usable.
        </p>
        <p>
          <NikkeCharacter mode="inline" slug="d" enablePopover />: Don’t get us
          wrong, D is actually insane here due to her insanely high amount of
          Ele self buff. We even saw some run in Elysion tower clearing Modernia
          (Wind-weak). Problem is, we don’t think many people invest in D, and
          if you have SBS, you mostly won’t need D to reach Stage 7 anyway. But
          hey, your resources, your choice.
        </p>
        <p>Here are some example teams you can try:</p>
        <h5>Team #1: SBS Center</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The funneling SBS team, also the most used one against Kraken
          currently. It has healing, shield, and insane DPS. The only problems
          of this team are: Naga is Electric (which she will receive extra
          damage from Kraken) and Alice is kinda required to fast tap to
          maximize her damage. You can run her on auto, it’s fine. Her damage is
          not the main factor this time.
        </p>
        <h5>Team #2: The Green Deal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="crown" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sakura-bloom-in-summer"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          There are a bunch of Nikke players who run this team instead of the
          above one. It has no healing so you may need to dodge some damage once
          or twice (depending on Crown’s shield). Because Sakura can farm a lot
          of parts, her damage is pretty solid. Alice is there to buff SBS so
          you don’t burst with her. You can also try Noir instead of Alice if
          your Noir has enough elemental, so she can make up for 30% DPS loss
          from SBS. You may also try out RedHood over S.Sakura if you happened
          to miss out on her limited banner.
        </p>
        <h5>Team #3: The Pilgrim-less</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="rosanna-chic-ocean"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sakura-bloom-in-summer"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is for people who fail to roll SBS and Crown (which are all
          Pilgrims and to hell with their abysmal rate), you can try this team.
          The main burst 3 users should be S.Sakura and D, with the support of
          Noir (because her burst deals nothing).
        </p>
        <h5>Team #4: I’m the wind that’s coming </h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Featuring NO LIMITED OR PILGRIMS Nikkes. The damage dealers will be D
          and Guilty, with the help of Noir. It’s not great, but it’s not
          terrible either. Blanc should keep your team so healthy that you can
          turn on auto most of the time (and make sure you break the QTE). D
          with her part damage buff for all attackers will melt those tentacles
          very fast.
        </p>
        <h5>Team #5: Bring in the Shotguns</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="soda-twinkling-bunny"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Another team with no Limited or Pilgrim Nikkes. Wind has 2 decent SGs
          in Noir and Guilty, which may be used against Kraken if you have them
          built enough. Guilty also keeps up Tove’s ATK buff stacks on Wind
          allies, and Noir’s hit rate buffs help a lot with core hits on the
          ever-exposed core of Kraken.
        </p>
        <SectionHeader title="Unleash the Kraken" />
        <p>
          Now you have your team, it’s time to fight Kraken. Kraken HP is
          verified by some top players who managed to kill it (and achieve the
          title: Prince of the sea)
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Stage</th>
              <th>Damage requirement</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>0 ~ 178,000,000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>178,000,001 ~ 475,000,000</td>
            </tr>
            <tr>
              <td>3</td>
              <td>475,000,001 ~ 890,000,000</td>
            </tr>
            <tr>
              <td>4</td>
              <td>890,000,001 ~ 1,364,000,000</td>
            </tr>
            <tr>
              <td>5</td>
              <td>1,364,000,001 ~ 2,194,000,000</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2,194,000,001 ~ 3,382,000,000</td>
            </tr>
            <tr>
              <td>7</td>
              <td>3,023,000,001 ~ 4,500,000,000</td>
            </tr>
            <tr>
              <td>8</td>
              <td>3,853,000,001 ~ 5,394,000,000</td>
            </tr>
            <tr>
              <td>9</td>
              <td>5,394,000,001+</td>
            </tr>
          </tbody>
        </Table>
        <h5>Phase I</h5>
        <StaticImage
          src="../../../images/nikke/ai/kraken_1.webp"
          alt="Kraken Interception"
        />
        <p>
          Phase 1 of Kraken will have some mechanics you should notice. Which
          are
        </p>
        <ul>
          <li>
            Destroy 2 back tentacles as fast as you can. They have very little
            HP, but if they survive long enough, they will spawn yellow drills
            which hurt your Nikke.
          </li>
          <li>
            The front tentacle is extremely tanky, and you should deal with it
            as much as you can. Because if they are not broken, Kraken will hit
            all 5 Nikkes, and it pierces through cover. The damage is not that
            high, except Naga will die very quickly
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/ai/kraken_2.webp"
          alt="Kraken Interception"
        />
        <ul>
          <li>
            Notice that the front Tentacle has an exposed core, try to aim it
            for even more damage (because SBS can’t pierce so she can only hit
            core during phase 1 through those tentacles).
          </li>
        </ul>
        <p>
          Tips: if you are on PC, turning off this setting will make the cursor
          not sync with crosshair. To move the crosshair, you will have to click
          and drag it instead. In exchange, your crosshair will be super sticky
          to the target, which is a strategy top players always use to deal with
          wiggle enemies (Indivilla for example). You won’t miss those core
          shots anymore.
        </p>
        <StaticImage
          src="../../../images/nikke/ai/kraken_3.webp"
          alt="Kraken Interception"
        />
        <p>
          After you have destroyed 2 front Tentacles (or they expire after
          beating you), Kraken will start charging Whirlwind like this.
        </p>
        <StaticImage
          src="../../../images/nikke/ai/kraken_4.webp"
          alt="Kraken Interception"
        />
        <p>
          Make sure to cover your Nikke if Crown shield is expired/lost, or you
          don’t have Tia/Crown at all. The damage can be serious, and even
          lethal to Naga.
        </p>
        <p>
          After this attack, Kraken will summon Elemental Shield. Only Wind-code
          Nikke can break those circles, so control SBS to break them manually,
          or if you are in Full Burst, just aim those circles.
        </p>
        <StaticImage
          src="../../../images/nikke/ai/kraken_5.webp"
          alt="Kraken Interception"
        />
        <p>
          After 4 Front Tentacles are destroyed (or expired), Kraken will enrage
          and reach phase II.
        </p>
        <h5>Phase II</h5>
        <StaticImage
          src="../../../images/nikke/ai/kraken_6.webp"
          alt="Kraken Interception"
        />
        <p>
          The core is now exposed so SBS will deal a lot of damage here. A few
          mechanics you should take notes:
        </p>
        <ul>
          <li>
            Front tentacles will now have low HP too, but they will attack
            sooner. Make sure to destroy it asap, or your Naga is gone.
          </li>
          <li>
            You will reach a QTE check before phase 2, and it has fail-cancel
            ring, so cover everyone, and only control 1 Nikke to break circles
            (try not using Shotgun, the spread is big so it may break
            fail-cancel rings instead)
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/ai/kraken_7.webp"
          alt="Kraken Interception"
        />
        <p>
          Failing this phase will end up in a wipe, so in case you fail, retry
          fast or your run is ruined.
        </p>
        <p>In phase 2, the rhythm goes like:</p>
        <p>
          Kraken summons 2 back tentacles + 1 front. When all are destroyed, it
          respawns the 3 tentacles. This scenario repeats a total of 4 times (4
          front Tentacles + 8 back tentacles) before it summons Elemental
          shield.
        </p>
        <StaticImage
          src="../../../images/nikke/ai/kraken_8.webp"
          alt="Kraken Interception"
        />
        <p>
          After the shield is gone, it will charge the whirlwind again. Duck or
          bye Naga.
        </p>
        <StaticImage
          src="../../../images/nikke/ai/kraken_9.webp"
          alt="Kraken Interception"
        />
        <p>
          After this attack, the pattern goes back to spawn 4 Front Tentacles +
          8 Back Tentacles =&gt; Elemental shield =&gt; Whirlwind till the end.
        </p>
        <p>
          Tips: Knowing that ONLY AFTER 4 Front Tentacles are destroyed, Kraken
          will summon elemental shield, you can purposely DELAY the destroying
          of front tentacles (but don’t take too long, if it attacks it hurts so
          bad) to make sure most of Alice’s burst is within the non elemental
          shield period (Because she will deal no damage during the shield), and
          SBS’s burst is within E-shield.
        </p>
        <p>
          Well, Kraken is not a heavy mechanic boss. It’s more about how many
          core shots you can land, and how much % Elemental Damage you have. We
          hope you can enjoy the 10 rock drops everyday, Prince of the Sea!
        </p>
      </div>
      <SectionHeader title="Gameplay Videos" />
      <p>
        For visual assistance, a good way to learn is through watching other
        players play. These videos will have varying skill levels, so that all
        bases are covered.
      </p>
      <p>
        Note: These runs may differ from your own in total damage dealt because
        of your Nikkes' skills, dupes, collection items, player skill itself,
        and especially Overload gear lines. It is recommended to at least reach
        recommended skill levels for each nikke and good overload lines before
        trying to replicate top level results. In some videos, especially stage
        9 clears, dupes may also play a part in survival of a nikke against boss
        attacks.
      </p>
      <Row className="video">
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="PAqk0Rmqg5M" />
        </Col>
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="SKQgPTzd96I" />
        </Col>
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="Awq45sazEbU" />
        </Col>
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="q1DAiSFZORQ" />
        </Col>
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="QOSt0ED_Kvc" />
        </Col>
        <Col xs={12} lg={4}>
          <YoutubeEmbed embedId="Mjh90RsvXBY" />
        </Col>
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesAIKraken;

export const Head: React.FC = () => (
  <Seo
    title="Anomaly Interception - Kraken | NIKKE | Prydwen Institute"
    description="  Struggling with clearing Anomaly Interception - Kraken? Check out our Guide below!."
    game="nikke"
  />
);
